import { useState } from "react";
import L from "leaflet";
import './ChoroplethLegend.css';

const ChoroplethLegend = ({map, colors, limits, upperLimit}) => {
  const [legend] = useState(L.control({ position: 'bottomleft' }))
  if (map) {
    const dmin = limits[0]
    const dmax = limits[limits.length - 1]

    // onAdd関数の状態を更新する
    legend.onAdd = () => {
      var s = '';
      const ratio = 1 / colors.length * 100
      for (let i = 0; i < colors.length; i++) {
        s += '<span style="display: inline-block; height: 20px; width: ' + ratio + '%; background-color:'+ colors[i] +'"></span>';
      }
      s += '<span class="domain-min">'+dmin.toFixed(1)+'</span>';
      s += '<span class="domain-med">'+((dmin + dmax)*0.5).toFixed(1)+'</span>';

      if (upperLimit) {
        s += '<span class="domain-max">'+dmax.toFixed(1)+'以上</span>';
      } else {
        s += '<span class="domain-max">'+dmax.toFixed(1)+'</span>';
      }

      const div = L.DomUtil.create('div', 'gradient');
      div.innerHTML = s
      return div;
    }

    legend.addTo(map);
  }

  return null;
}

export default ChoroplethLegend;