import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  Tabs,
  Tab,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Button,
} from '@mui/material';

import SalesPrice from './SalesPrice';
import RentalPrice from './RentalPrice';
import LandPrice from './LandPrice';
import Population from "./Population";
// import { ROPPONGI_OFFICE_ADDRESS } from '../constants/DefaultInput';
import { ADDRESS_API_URL } from '../constants/Urls';
import { inputActions } from "../ducks/input";
import { salesPriceOperations } from '../ducks/salesPrice';
import { rentalPriceOperations } from '../ducks/rentalPrice';
import { landPriceOperations } from '../ducks/landPrice';
import { populationOperations } from '../ducks/population';

import { Amplify, Auth } from 'aws-amplify';
// import awsconfig from "../aws-exports";
const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
// delete awsconfig.oauth
Amplify.configure({
  // ...awsconfig,
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: "ap-northeast-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      // 環境変数の名前は適宜変更する
      domain: `.${process.env.REACT_APP_CUSTOM_DOMAIN}`,
    },
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: "marketVisualizationGraphDataLambda",
        endpoint: process.env.REACT_APP_GRAPH_DATA_API_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "marketVisualizationMapDataLambda",
        endpoint: process.env.REACT_APP_MAP_DATA_API_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "ADDRESS_API",
        endpoint: process.env.REACT_APP_ADDRESS_API_URL,
        custom_header: async () => {
          const token = (await Auth.currentSession())
          .getAccessToken()
          .getJwtToken();
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      }
    ]
  }
});



const Main = (props) => {
  useEffect(() => {
    props.getSalesPriceMapData();
    props.getSalesPriceGraphData(props.lat, props.lon, props.radius);
    props.getLandPriceMapData();
    props.getLandPriceGraphData(props.lat, props.lon, props.radius);
    props.getPopulationMapData();
    props.getPopulationGraphData(props.lat, props.lon, props.radius);
    props.getRentalPriceMapData();
    props.getRentalPriceGraphData(props.lat, props.lon, props.radius);
    // eslint-disable-next-line
  }, []);

  const [tabValue, setTabValue] = useState(0);
  const [address, setAddress] = useState();
  const [addressError, setAddressError] = useState('');

  const handleChangeTabValue = (e, newValue) => {
    setTabValue(newValue)
  }

  const handleChangeAddress = (e) => {
    setAddress(e.target.value)
  }

  function convertAddressToLatLon() {
    return async() =>{
      try {
        // const user = await Auth.currentAuthenticatedUser();
        // const token = user.signInUserSession.idToken.jwtToken;

        const options = {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
          },
          body: JSON.stringify({
              address: address,
              response_type: 'full'
          })
        }
        fetch(ADDRESS_API_URL, options)
          .then(res => res.json())
          .then(data => {
            if (data === null) {
              setAddressError('住所が見つかりませんでした');
            } else if (data.lat !== null & data.lon !== null) {
              handleChangeLatLon(data.lat, data.lon);
              setAddressError('');
            } else {
              setAddressError('適切な住所を入力してください');
            }
          })
          .catch(error => {
            console.error('Error', error);
            setAddressError('適切な住所を入力してください');
          })
      } catch (error) {
        console.error("Authentication error:", error);
      }
    }
  }

  const handleChangeLatLon = (lat, lon) => {
    props.updateLatLon(lat, lon);
    props.getSalesPriceGraphData(lat, lon, props.radius);
    props.getLandPriceGraphData(lat, lon, props.radius);
    props.getPopulationGraphData(lat, lon, props.radius);
    props.getRentalPriceGraphData(lat, lon, props.radius);
  }

  const handleButtonClick = () => {
    if (address === '')  {
      setAddressError('住所を入力してください');
    } else {
      const convertFunc = convertAddressToLatLon();
      convertFunc();
    }
  }

  const switchMap = () => {
    switch (tabValue) {
      case 0:
        return <SalesPrice />
      case 1:
        return <RentalPrice />
      case 2:
        return <LandPrice />
      case 3:
        return <Population />
      default:
        return null;
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} style={{ height: '10%' }}>
          <Paper>
            <Tabs 
              value={tabValue} 
              onChange={handleChangeTabValue}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label='価格指数' />
              <Tab label='賃料指数' />
              <Tab label='地価データ' />
              <Tab label='人口データ' />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={4} style={{ paddingLeft: 20, paddingTop: 10, height: '10%' }}>
          <FormControl style={{ 'width': '100%' }}>
            <FormLabel >住所（地図上をクリックして検索することもできます）</FormLabel>
            <TextField
              name='address'
              value={address}
              onChange={handleChangeAddress}
              fullWidth
              error={addressError !== ''}
              helperText={addressError !== '' ? addressError: ''}
              placeholder="東京都港区六本木３丁目２−１"
            />
          </FormControl>
        </Grid>
        <Grid item xs={8} style={{ paddingLeft: 20, paddingTop: 40, height: '10%' }}>
          <Button
            variant='contained'
            onClick={handleButtonClick}
          >
            結果を表示
          </Button>
        </Grid>
        <Grid item xs={12} style={{ height: '80%' }}>
            { switchMap() } 
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lat: state.input.lat,
    lon: state.input.lon,
    radius: state.input.radius,
    compare_year: state.input.compare_year
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateLatLon(lat, lon) { dispatch(inputActions.updateLatLon(lat, lon)) },
    getSalesPriceMapData() { dispatch(salesPriceOperations.getMapDataAPI()) },
    getSalesPriceGraphData(lat, lon, radius) { dispatch(salesPriceOperations.getGraphDataAPI(lat, lon, radius)) },
    getRentalPriceMapData() { dispatch(rentalPriceOperations.getMapDataAPI()) },
    getRentalPriceGraphData(lat, lon, radius) { dispatch(rentalPriceOperations.getGraphDataAPI(lat, lon, radius)) },
    getLandPriceMapData() { dispatch(landPriceOperations.getMapDataAPI()) },
    getLandPriceGraphData(lat, lon, radius) { dispatch(landPriceOperations.getGraphDataAPI(lat, lon, radius)) },
    getPopulationMapData() { dispatch(populationOperations.getMapDataAPI()) },
    getPopulationGraphData(lat, lon, radius) { dispatch(populationOperations.getGraphDataAPI(lat, lon, radius)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);