const LAND_PRICE_GET_MAP_DATA_PENDING = 'LAND_PRICE_GET_MAP_DATA_PENDING';
const LAND_PRICE_GET_MAP_DATA_SUCCESS = 'LAND_PRICE_GET_MAP_DATA_SUCCESS';
const LAND_PRICE_GET_MAP_DATA_FAILURE = 'LAND_PRICE_GET_MAP_DATA_FAILURE';
const LAND_PRICE_GET_GRAPH_DATA_PENDING = 'LAND_PRICE_GET_GRAPH_DATA_PENDING';
const LAND_PRICE_GET_GRAPH_DATA_SUCCESS = 'LAND_PRICE_GET_GRAPH_DATA_SUCCESS';
const LAND_PRICE_GET_GRAPH_DATA_FAILURE = 'LAND_PRICE_GET_GRAPH_DATA_FAILURE';

export {
  LAND_PRICE_GET_MAP_DATA_PENDING,
  LAND_PRICE_GET_MAP_DATA_SUCCESS,
  LAND_PRICE_GET_MAP_DATA_FAILURE,
  LAND_PRICE_GET_GRAPH_DATA_PENDING,
  LAND_PRICE_GET_GRAPH_DATA_SUCCESS,
  LAND_PRICE_GET_GRAPH_DATA_FAILURE
}