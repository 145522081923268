const RENTAL_PRICE_GET_MAP_DATA_PENDING = 'RENTAL_PRICE_GET_MAP_DATA_PENDING';
const RENTAL_PRICE_GET_MAP_DATA_SUCCESS = 'RENTAL_PRICE_GET_MAP_DATA_SUCCESS';
const RENTAL_PRICE_GET_MAP_DATA_FAILURE = 'RENTAL_PRICE_GET_MAP_DATA_FAILURE';
const RENTAL_PRICE_GET_GRAPH_DATA_PENDING = 'RENTAL_PRICE_GET_GRAPH_DATA_PENDING';
const RENTAL_PRICE_GET_GRAPH_DATA_SUCCESS = 'RENTAL_PRICE_GET_GRAPH_DATA_SUCCESS';
const RENTAL_PRICE_GET_GRAPH_DATA_FAILURE = 'RENTAL_PRICE_GET_GRAPH_DATA_FAILURE';

export {
  RENTAL_PRICE_GET_MAP_DATA_PENDING,
  RENTAL_PRICE_GET_MAP_DATA_SUCCESS,
  RENTAL_PRICE_GET_MAP_DATA_FAILURE,
  RENTAL_PRICE_GET_GRAPH_DATA_PENDING,
  RENTAL_PRICE_GET_GRAPH_DATA_SUCCESS,
  RENTAL_PRICE_GET_GRAPH_DATA_FAILURE
}