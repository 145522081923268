import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Select, MenuItem } from '@mui/material';
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Circle,
  Marker,
  useMapEvents
} from 'react-leaflet';
import chroma from 'chroma-js'

import { MAX_YEAR, ROPPONGI_OFFICE_LAT_LON, MAP_ZOOM } from '../constants/DefaultInput';
import ChoroplethLegend from '../components/choroplethLegend/ChoroplethLegend';
import { inputActions } from '../ducks/input';
import { salesPriceOperations } from '../ducks/salesPrice';
import { rentalPriceOperations } from '../ducks/rentalPrice';
import { landPriceOperations } from '../ducks/landPrice';
import { populationOperations } from '../ducks/population';

const ChoroplethMap = (props) => {
  const [map, setMap] = useState(null);
  useEffect(() => {
    if (props.lat && props.lon && map) {
      map.flyTo([props.lat, props.lon], map.getZoom());
    }
  }, [props.lat, props.lon, map]);

  const handleChangeCompareYear = (e) => {
    props.updateCompareYear(e.target.value);
  }

  const LocationMarker = ({props}) => {
    const map = useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        props.updateLatLon(lat, lng);
        props.getSalesPriceGraphData(lat, lng, props.radius);
        props.getLandPriceGraphData(lat, lng, props.radius);
        props.getPopulationGraphData(lat, lng, props.radius);
        props.getRentalPriceGraphData(lat, lng, props.radius);
        map.flyTo(e.latlng, map.getZoom());
      }
    })

    useEffect(() => {
      if (map) {
        map.invalidateSize();
      }
    }, [map]);

    return props.lat === null || props.lon === null || props.radius === null ? null : (
      <div>
        <Marker position={[props.lat, props.lon]}/>
        <Circle center={[props.lat, props.lon]} radius={props.radius}/>
      </div>
    )
  }

  function updateObject(target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i];
      if (source) {
        for (let key in source) {
          if (source.hasOwnProperty(key)) {
            target[key] = source[key]; 
          }
        }
      }
    }

    return target
  }

  const getStyle = (limits, colors, feature) => {
    var fillColorStyle = {}
    const featureValue = calcRatio(feature)
    if (!isNaN(featureValue) && featureValue !== null) {
      for (let i = 0; i < limits.length; i++) {
        if (featureValue <= limits[i]) {
          fillColorStyle.fillColor = colors[i]
          break
        }
      }
    } else {
      fillColorStyle.fillColor = 'black';
    }

    switch (typeof props.style) {
      case 'function':
        return updateObject(fillColorStyle, props.style(feature))
      case 'object':
        return updateObject(fillColorStyle, props.style)
      default:
        return fillColorStyle
    }
  }

  const date = new Date();
  const this_year = Math.min(date.getFullYear(), MAX_YEAR);

  const calcRatio = (item) => {
    if (props.isCountData) {
      return item.properties.count
    }

    if (item.properties[props.compare_year] > 0) {
      var ratio;
      if (item.properties[this_year] === null) {
        ratio = item.properties[this_year - 1] / item.properties[props.compare_year];
      } else {
        ratio = item.properties[this_year] / item.properties[props.compare_year];
      }

      if (ratio <= props.upperRatioLimit) {
        return ratio
      } else {
        return props.upperRatioLimit
      }
    } else {
      return null
    }
  }

  var values = props.data.features.map(x => calcRatio(x));
  // nullを削除
  values = values.filter(v => v);

  const limits = chroma.limits(values, props.mode, props.steps - 1);
  const colors = chroma.scale(props.scale).colors(props.steps);

  const years_list = Array.from(new Array(21), (x, i) => i + 2000);
  return (
    <div style={{ height: '100%' }}>
          <div>
            {props.lat && props.lon && (
              <p style={{ marginLeft: '20px' }}>地図上で選択された位置：緯度 {props.lat}, 経度 {props.lon}</p>
            )}
          </div>
      <div style={{ height: '10%' }}>
          { props.isSelectYear ?
            (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                p={1}
              >
                <Box>
                  <Select value={props.compare_year} onChange={handleChangeCompareYear}>
                    {years_list.map(year => <MenuItem value={year} key={year}>{year}</MenuItem> )}
                  </Select>
                </Box>
                <Box fontWeight='fontWeightBold'>年からの成長率</Box>
                </Box>
            ) : null
          }
      </div>
      <MapContainer style={{ height: '90%', width: '100%' }} zoom={MAP_ZOOM} center={ROPPONGI_OFFICE_LAT_LON} ref={setMap}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker props={props}/>
        <GeoJSON 
          data={props.data.features}
          style={(feature) => getStyle(limits, colors, feature)}
          onEachFeature={props.onEachFeature}
          pointToLayer={props.pointToLayer}
        />
        {
          props.isLegend ?
            <ChoroplethLegend
              map={map}
              colors={colors}
              limits={limits}
              upperLimit={props.upperRatioLimit}
            />
          : null
        }
    </MapContainer>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    lat: state.input.lat,
    lon: state.input.lon,
    radius: state.input.radius,
    compare_year: state.input.compare_year
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateLatLon(lat, lon) { dispatch(inputActions.updateLatLon(lat, lon)) },
    updateCompareYear(year) { dispatch(inputActions.updateCompareYear(year)) },
    getSalesPriceGraphData(lat, lon, radius) { dispatch(salesPriceOperations.getGraphDataAPI(lat, lon, radius)) },
    getRentalPriceGraphData(lat, lon, radius) { dispatch(rentalPriceOperations.getGraphDataAPI(lat, lon, radius)) },
    getLandPriceGraphData(lat, lon, radius) { dispatch(landPriceOperations.getGraphDataAPI(lat, lon, radius)) },
    getPopulationGraphData(lat, lon, radius) { dispatch(populationOperations.getGraphDataAPI(lat, lon, radius)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoroplethMap);