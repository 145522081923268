const SALES_PRICE_GET_MAP_DATA_PENDING = 'SALES_PRICE_GET_MAP_DATA_PENDING';
const SALES_PRICE_GET_MAP_DATA_SUCCESS = 'SALES_PRICE_GET_MAP_DATA_SUCCESS';
const SALES_PRICE_GET_MAP_DATA_FAILURE = 'SALES_PRICE_GET_MAP_DATA_FAILURE';
const SALES_PRICE_GET_GRAPH_DATA_PENDING = 'SALES_PRICE_GET_GRAPH_DATA_PENDING';
const SALES_PRICE_GET_GRAPH_DATA_SUCCESS = 'SALES_PRICE_GET_GRAPH_DATA_SUCCESS';
const SALES_PRICE_GET_GRAPH_DATA_FAILURE = 'SALES_PRICE_GET_GRAPH_DATA_FAILURE';

export {
  SALES_PRICE_GET_MAP_DATA_PENDING,
  SALES_PRICE_GET_MAP_DATA_SUCCESS,
  SALES_PRICE_GET_MAP_DATA_FAILURE,
  SALES_PRICE_GET_GRAPH_DATA_PENDING,
  SALES_PRICE_GET_GRAPH_DATA_SUCCESS,
  SALES_PRICE_GET_GRAPH_DATA_FAILURE
}