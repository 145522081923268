import * as types from './types';

export const initialState = {
  isPending: false,
  mapData: null,
  graphData: null,
  error: null
}

function reducer(state=initialState, action) {
  switch (action.type) {
    case types.POPULATION_GET_MAP_DATA_PENDING:
      return {
        ...state,
        isPending: action.isPending
      }

    case types.POPULATION_GET_MAP_DATA_SUCCESS:
      return {
        ...state,
        isPending: action.isPending,
        mapData: action.mapData
      }

    case types.POPULATION_GET_MAP_DATA_FAILURE:
      return {
        ...state,
        isPending: action.isPending,
        error: state.error
      }

    case types.POPULATION_GET_GRAPH_DATA_PENDING:
      return {
        ...state,
        isPending: action.isPending
      }

    case types.POPULATION_GET_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        isPending: action.isPending,
        graphData: action.graphData
      }

    case types.POPULATION_GET_GRAPH_DATA_FAILURE:
      return {
        ...state,
        isPending: action.isPending,
        graphData: null, 
        error: state.error
      }
  
    default:
      return state;
  }
}

export default reducer;