const POPULATION_GET_MAP_DATA_PENDING = 'POPULATION_GET_MAP_DATA_PENDING';
const POPULATION_GET_MAP_DATA_SUCCESS = 'POPULATION_GET_MAP_DATA_SUCCESS';
const POPULATION_GET_MAP_DATA_FAILURE = 'POPULATION_GET_MAP_DATA_FAILURE';
const POPULATION_GET_GRAPH_DATA_PENDING = 'POPULATION_GET_GRAPH_DATA_PENDING';
const POPULATION_GET_GRAPH_DATA_SUCCESS = 'POPULATION_GET_GRAPH_DATA_SUCCESS';
const POPULATION_GET_GRAPH_DATA_FAILURE = 'POPULATION_GET_GRAPH_DATA_FAILURE';

export {
  POPULATION_GET_MAP_DATA_PENDING,
  POPULATION_GET_MAP_DATA_SUCCESS,
  POPULATION_GET_MAP_DATA_FAILURE,
  POPULATION_GET_GRAPH_DATA_PENDING,
  POPULATION_GET_GRAPH_DATA_SUCCESS,
  POPULATION_GET_GRAPH_DATA_FAILURE
}