import * as types from './types';

function getMapDataPending() {
  return {
    type: types.RENTAL_PRICE_GET_MAP_DATA_PENDING,
    isPending: true
  }
}

function getMapDataSuccess(data) {
  return {
    type: types.RENTAL_PRICE_GET_MAP_DATA_SUCCESS,
    isPending: false,
    mapData: data
  }
}

function getMapDataFailure(error) {
  return {
    type: types.RENTAL_PRICE_GET_MAP_DATA_FAILURE,
    isPending: false,
    error: error
  }
}

function getGraphDataPending() {
  return {
    type: types.RENTAL_PRICE_GET_GRAPH_DATA_PENDING,
    isPending: true
  }
}

function getGraphDataSuccess(data) {
  return {
    type: types.RENTAL_PRICE_GET_GRAPH_DATA_SUCCESS,
    isPending: false,
    graphData: data
  }
}

function getGraphDataFailure(error) {
  return {
    type: types.RENTAL_PRICE_GET_GRAPH_DATA_FAILURE,
    isPending: false,
    error: error
  }
}

export {
  getMapDataPending,
  getMapDataSuccess,
  getMapDataFailure,
  getGraphDataPending,
  getGraphDataSuccess,
  getGraphDataFailure
}