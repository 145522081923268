import * as types from './types';

export const initialState = {
  isMapDataPending: false,
  isGraphDataPending: false,
  mapData: null,
  graphData: null,
  error: null
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SALES_PRICE_GET_MAP_DATA_PENDING:
      return {
        ...state,
        isMapDataPending: action.isPending
      }

    case types.SALES_PRICE_GET_MAP_DATA_SUCCESS:
      return {
        ...state,
        isMapDataPending: action.isPending,
        mapData: action.mapData
      }

    case types.SALES_PRICE_GET_MAP_DATA_FAILURE:
      return {
        ...state,
        isMapDataPending: action.isPending,
        error: state.error
      }

    case types.SALES_PRICE_GET_GRAPH_DATA_PENDING:
      return {
        ...state,
        isGraphDataPending: action.isPending
      }

    case types.SALES_PRICE_GET_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        isGraphDataPending: action.isPending,
        graphData: action.graphData
      }

    case types.SALES_PRICE_GET_GRAPH_DATA_FAILURE:
      return {
        ...state,
        isGraphDataPending: action.isPending,
        error: state.error
      }

    default:
      return state;
  }
}

export default reducer;