import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import GraphForArea from './GraphForArea';
import ChoroplethMap from './ChoroplethMap';

const Population = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  let { isPending, mapData, graphData } = props;
  useEffect(() => {
    if (graphData === null) {
      setErrorMessage('人口データの範囲外です。東京23区内で住所を入力、もしくはクリックしてください');
    } else {
      setErrorMessage('');
    }
  }, [graphData]);
  var series = [{
    name: '',
    data: graphData === null ? [] : graphData.y_values.map(y => y > 0 ? Math.round(y) : null)
  }];

  var options = {
    chart: {
      type: 'line',
      height: '50%',
      width: '100%',
      zoom: { enabled: false },
      toolbar: { show: false }
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    xaxis: {
      categories: graphData === null ? [] : graphData.x_values,
      title: {
        text: '西暦',
        // offsetY: -10,
      },
    },
    yaxis: [{
      title: {
        text: '総人口（人）',
      },
      labels: {
        formatter: function (value) {
          return value.toLocaleString();
        }
      }
    }]
  }

  const mapStyle = {
    weight: 1,
    color: 'white',
    fillOpacity: 0.6
  }

  return (
    <div style={{ height: '100%' }}>
      {
        isPending ? (
          <Grid container flexDirection="column" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography>データ取得中...</Typography>
            </Grid>
          </Grid>
        ) : (
          mapData === null ? null :
            (
              <Grid container justify='center' style={{ height: '100%' }}>
                <Grid item xs={7}>
                  <ChoroplethMap
                    data={mapData}
                    isLegend={true}
                    isSelectYear={true}
                    scale={'BuGn'}
                    steps={100}
                    mode={'e'}
                    style={mapStyle}
                    upperRatioLimit={2}
                  />
                </Grid>
                <Grid item xs={5} style={{ padding: 10 }}>
                  {errorMessage && (
                    <div style={{ color: 'red', fontWeight: 'bold', fontSize: '0.8em', padding: '10px', border: '1px solid red', borderRadius: '5px', margin: '10px 0', backgroundColor: '#ffe6e6' }}>
                      {errorMessage}
                    </div>
                  )}
                  <GraphForArea
                    options={options}
                    series={series}
                    title={'総人口推移'}
                  />
                  <div style={{ padding: 10 }}>
                    <Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>このデータについて</Typography>
                    <Typography variant='body2' style={{ fontSize: '0.75em' }}>• 東京都総務局統計部の住民基本台帳による東京都の人口データを加工して作成</Typography>
                    <Typography variant='body2' style={{ fontSize: '0.75em' }}>• 地図上の黒色の区域は、選択した年度の調査で住民がいない区域を表す</Typography>
                    <Typography variant='body2' style={{ fontSize: '0.75em' }}>• 2012年に住民基本台帳の制度が外国籍の住民にも適応されたため、2012年から2013年に人口の急増が起こる可能性がある</Typography>
                  </div>
                </Grid>
              </Grid>
            )
        )
      }
    </div>
  )
}


const mapStateToProps = state => {
  return {
    isPending: state.population.isPending,
    mapData: state.population.mapData,
    graphData: state.population.graphData
  }
}

export default connect(mapStateToProps, null)(Population);