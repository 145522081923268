import * as actions from './actions';
import {
  RENTAL_PRICE_MAP_DATA_API_URL,
  RENTAL_PRICE_GRAPH_DATA_API_URL
} from '../../constants/Urls';

import { Amplify, Auth } from 'aws-amplify';
import pako from 'pako';
// import awsconfig from "../../aws-exports";
// Amplify.configure({
//   API: {
//     endpoints: [
//       {
//         name: "marketVisualizationGraphDataLambda",
//         endpoint: process.env.REACT_APP_GRAPH_DATA_API_URL
//       },
//       {
//         name: "marketVisualizationMapDataLambda",
//         endpoint: process.env.REACT_APP_MAP_DATA_API_URL
//       },
//       {
//         name: "ADDRESS_API",
//         endpoint: process.env.REACT_APP_ADDRESS_API_URL
//       }
//     ]
//   }
// });
const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
// delete awsconfig.oauth
Amplify.configure({
  // ...awsconfig,
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: "ap-northeast-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      // 環境変数の名前は適宜変更する
      domain: `.${process.env.REACT_APP_CUSTOM_DOMAIN}`,
    },
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: "marketVisualizationGraphDataLambda",
        endpoint: process.env.REACT_APP_GRAPH_DATA_API_URL,
        custom_header: async () => {
          const token = (await Auth.currentSession())
            .getAccessToken()
            .getJwtToken();
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      },
      {
        name: "marketVisualizationMapDataLambda",
        endpoint: process.env.REACT_APP_MAP_DATA_API_URL,
        custom_header: async () => {
          const token = (await Auth.currentSession())
            .getAccessToken()
            .getJwtToken();
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      },
      {
        name: "ADDRESS_API",
        endpoint: process.env.REACT_APP_ADDRESS_API_URL,
        custom_header: async () => {
          const token = (await Auth.currentSession())
            .getAccessToken()
            .getJwtToken();
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      }
    ]
  }
});

function getMapDataAPI() {
  return async (dispatch) => {
    try {
      // const user = await Auth.currentAuthenticatedUser();
      // const token = user.signInUserSession.idToken.jwtToken;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
        body: JSON.stringify({
          "data_name": "rental_price",
        }),
      };

      dispatch(actions.getMapDataPending());

      fetch(RENTAL_PRICE_MAP_DATA_API_URL, options)
        .then((res) => {
          return res.json();
        }).then((response_json) => {
          // if (response_json.headers["Content-Encoding"] === "gzip") {
          //   let body = response_json.body;
          //   let binaryData = atob(body);

          //   // バイナリデータを Uint8Array に変換
          //   let byteArray = new Uint8Array(binaryData.length);
          //   for (let i = 0; i < binaryData.length; i++) {
          //     byteArray[i] = binaryData.charCodeAt(i);
          //   }

          //   // gzip 解凍
          //   let decompressedData = pako.inflate(byteArray, { to: "string" });

          //   // JSON パース
          //   let jsonData = JSON.parse(decompressedData);
          //   response_json.body = JSON.stringify(jsonData)
          //   dispatch(actions.getMapDataSuccess(response_json));
          // } else {
            dispatch(actions.getMapDataSuccess(response_json));
          // }
        })
        .catch((error) => {
          dispatch(actions.getMapDataFailure(error));
          console.error("Error", error);
        });
    } catch (error) {
      console.error("Authentication error:", error);
    }
  };
}


function getGraphDataAPI(lat, lon, radius) {
  return async (dispatch) => {
    try {
      // const user = await Auth.currentAuthenticatedUser();
      // const token = user.signInUserSession.idToken.jwtToken;

      const options = {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
        body: JSON.stringify({
          lat: lat,
          lon: lon,
          radius: radius,
          data_name: "rental_price",
        }),
      };

      dispatch(actions.getGraphDataPending());

      fetch(RENTAL_PRICE_GRAPH_DATA_API_URL, options)
        .then((res) => {
          if (res.headers.get("Content-Encoding") === "gzip") {
            return res.text(); // レスポンスボディをテキストとして取得
          } else {
            return res.json(); // 通常の JSON レスポンスとして処理
          }
        })
        .then(async (dataOrText) => {
          if (typeof dataOrText === "string") {
            // Base64 デコード
            const binaryData = atob(dataOrText);

            // バイナリデータを Uint8Array に変換
            const byteArray = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
              byteArray[i] = binaryData.charCodeAt(i);
            }

            // gzip 解凍
            const decompressedData = pako.inflate(byteArray, { to: "string" });

            // JSON パース
            const jsonData = JSON.parse(decompressedData);
            dispatch(actions.getGraphDataSuccess(jsonData));
          } else {
            // 通常の JSON レスポンスとして処理
            dispatch(actions.getGraphDataSuccess(dataOrText));
          }
        })
        .catch((error) => {
          dispatch(actions.getGraphDataFailure(error));
          console.error("Error", error);
        });
    } catch (error) {
      console.error("Authentication error:", error);
    }
  };
}



export {
  getMapDataAPI,
  getGraphDataAPI
}