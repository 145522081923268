import React from 'react';
// import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Main from './containers/Main';



// function AppContent({ signOut, user }) {
//     let handleSignOut = (e) => {
//         e.preventDefault();
//         signOut();
//     };

//     return (
//         <div id="app">
            // <header className="navbar is-black">
            //     <div className="navbar-end">
            //         <div className="navbar-item">
            //             <button className="button is-white" onClick={handleSignOut}>Sign out</button>
            //         </div>
            //     </div>
            // </header>
//             <main>
                // <section>
                //     <div>
                //         <Main />
                //     </div>
                // </section>
//             </main>
//         </div>
//     );

// }

// export default withAuthenticator(AppContent, { hideSignUp: true });
// import React from 'react';
import '@aws-amplify/ui-react/styles.css';
// import Main from './containers/Main';
import { Authenticator, Text, View, useTheme } from '@aws-amplify/ui-react';

export default function App() {
    return (
      <Authenticator hideSignUp socialProviders={['google']} components={{
        Footer() {
          const { tokens } = useTheme();

          return (
            <View textAlign="center" padding={tokens.space.large}>
              <Text color={tokens.colors.neutral[80]}>
                ログインすると、
                <a
                  href={`https://${
                    process.env.REACT_APP_CUSTOM_DOMAIN
                  }/term_of_service.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  利用規約
                </a>
                に同意したものとします
              </Text>
              <Text color={tokens.colors.neutral[80]}>
                &copy; GA technologies AI Strategy Center. All Rights Reserved
              </Text>
            </View>
          );
        },
        SignIn: {},
      }}>
        {({ signOut }) => (
          <main>
                <header className="navbar is-black">
                 <div className="navbar-end">
                     <div className="navbar-item">
                         <button className="button is-white" onClick={signOut}>Sign out</button>
                     </div>
                 </div>
                </header>
                <Main />
          </main>
        )}
      </Authenticator>
    );
  }