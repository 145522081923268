import reducer from "./reducers";
import * as inputOperations from './operatioins'
import * as inputActions from './actions';
import * as inputTypes from './types';

export {
  inputOperations,
  inputActions,
  inputTypes
}

export default reducer;