export const ADDRESS_API_URL = process.env.REACT_APP_ADDRESS_API_URL;

export const SALES_PRICE_MAP_DATA_API_URL = process.env.REACT_APP_MAP_DATA_API_URL;
export const SALES_PRICE_GRAPH_DATA_API_URL = process.env.REACT_APP_GRAPH_DATA_API_URL;

export const RENTAL_PRICE_MAP_DATA_API_URL = process.env.REACT_APP_MAP_DATA_API_URL;
export const RENTAL_PRICE_GRAPH_DATA_API_URL = process.env.REACT_APP_GRAPH_DATA_API_URL;

export const LAND_PRICE_MAP_DATA_API_URL = process.env.REACT_APP_MAP_DATA_API_URL;
export const LAND_PRICE_GRAPH_DATA_API_URL = process.env.REACT_APP_GRAPH_DATA_API_URL;

export const POPULATION_MAP_DATA_API_URL = process.env.REACT_APP_MAP_DATA_API_URL;
export const POPULATION_GRAPH_DATA_API_URL = process.env.REACT_APP_GRAPH_DATA_API_URL;