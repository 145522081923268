import { connect } from 'react-redux';
import { Box, Select, MenuItem } from '@mui/material';
import ReactApexChart from "react-apexcharts";

import { inputActions } from "../ducks/input";
import { salesPriceOperations } from '../ducks/salesPrice';
import { rentalPriceOperations } from '../ducks/rentalPrice';
import { landPriceOperations } from '../ducks/landPrice';
import { populationOperations } from '../ducks/population';

const GraphForArea = (props) => {
  const handleChangeRadius = (e) => {
    props.updateRadius(e.target.value);
    props.getSalesPriceGraphData(props.lat, props.lon, e.target.value);
    props.getLandPriceGraphData(props.lat, props.lon, e.target.value);
    props.getPopulationGraphData(props.lat, props.lon, e.target.value);
    props.getRentalPriceGraphData(props.lat, props.lon, e.target.value);
  }

  return (
    <div>
      <Box 
        display='flex' 
        justifyContent='center' 
        alignItems='center'
        p={1}
      >
        <Box>
          <Select value={props.radius} onChange={handleChangeRadius}>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
            <MenuItem value={1500}>1500</MenuItem>
            <MenuItem value={2000}>2000</MenuItem>
          </Select>
        </Box>
        <Box fontWeight='fontWeightBold'>m 圏内の{props.title}</Box>
      </Box>
      <ReactApexChart
        options={props.options}
        series={props.series}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lat: state.input.lat,
    lon: state.input.lon,
    radius: state.input.radius
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateRadius(radius) { dispatch(inputActions.updateRadius(radius)) },
    getSalesPriceGraphData(lat, lon, radius) { dispatch(salesPriceOperations.getGraphDataAPI(lat, lon, radius)) },
    getRentalPriceGraphData(lat, lon, radius) { dispatch(rentalPriceOperations.getGraphDataAPI(lat, lon, radius)) },
    getLandPriceGraphData(lat, lon, radius) { dispatch(landPriceOperations.getGraphDataAPI(lat, lon, radius)) },
    getPopulationGraphData(lat, lon, radius) { dispatch(populationOperations.getGraphDataAPI(lat, lon, radius)) },
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(GraphForArea);