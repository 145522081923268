import { combineReducers } from 'redux';

import inputReducer from './input';
import salesPriceReducer from './salesPrice';
import rentalPriceReducer from './rentalPrice';
import landPriceReducer from './landPrice';
import populationReducer from './population';

const rootReducer = combineReducers({
  input: inputReducer,
  salesPrice: salesPriceReducer,
  rentalPrice: rentalPriceReducer,
  landPrice: landPriceReducer,
  population: populationReducer
});

export default rootReducer;