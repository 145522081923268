import reducer from './reducers';
import * as rentalPriceOperations from './operations';
import * as rentalPriceActions from './actions';
import * as rentalPriceTypes from './types';

export {
  rentalPriceOperations,
  rentalPriceActions,
  rentalPriceTypes
}

export default reducer;