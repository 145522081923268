import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from 'redux';

import { Amplify, Auth } from 'aws-amplify';
// import config from './aws-exports';
// import awsconfig from "./aws-exports";

import "bulma/css/bulma.css";
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './ducks/rootReducer';

const store = createStore(rootReducer, applyMiddleware(thunk))
const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
// delete awsconfig.oauth
Amplify.configure({
  // ...awsconfig,
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: "ap-northeast-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      // 環境変数の名前は適宜変更する
      domain: `.${process.env.REACT_APP_CUSTOM_DOMAIN}`,
    },
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: "marketVisualizationGraphDataLambda",
        endpoint: process.env.REACT_APP_GRAPH_DATA_API_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "marketVisualizationMapDataLambda",
        endpoint: process.env.REACT_APP_MAP_DATA_API_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: "ADDRESS_API",
        endpoint: process.env.REACT_APP_ADDRESS_API_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      }
    ]
  }
});
// const API_CONFIG = {
//   endpoints: [
//     {
//       name: "marketVisualizationGraphDataLambda",
//       endpoint: process.env.REACT_APP_GRAPH_DATA_API_URL
//     },
//     {
//       name: "marketVisualizationMapDataLambda",
//       endpoint: process.env.REACT_APP_MAP_DATA_API_URL

//     },
//     {
//       name: "ADDRESS_API",
//       endpoint: process.env.REACT_APP_ADDRESS_API_URL

//     }
//   ]
// }
// config['API'] = API_CONFIG

// let redirectURLs = config.oauth.redirectSignIn.split(",");
// // const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

// for (let i = 0; i < redirectURLs.length; i++) {
//   if (window.location.href.indexOf(redirectURLs[i]) >= 0) {
//     config.oauth.redirectSignIn = redirectURLs[i];
//   }
// }
// redirectURLs = config.oauth.redirectSignOut.split(",");
// for (let i = 0; i < redirectURLs.length; i++) {
//   if (window.location.href.indexOf(redirectURLs[i]) >= 0) {
//     config.oauth.redirectSignOut = redirectURLs[i];
//   }
// }

// Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={ store }>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();