import * as types from './types';
import { ROPPONGI_OFFICE_LAT_LON } from '../../constants/DefaultInput';

const initialState = {
  lat: ROPPONGI_OFFICE_LAT_LON[0],
  lon: ROPPONGI_OFFICE_LAT_LON[1],
  radius: 1000,
  compare_year: 2016,
}

function reducer(state=initialState, action) {
  switch (action.type) {
    case types.UPDATE_LATLON:
      return {
        ...state,
        lat: action.lat,
        lon: action.lon
      }

    case types.UPDATE_RADIUS:
      return {
        ...state,
        radius: action.radius
      }

    case types.UPDATE_COMPARE_YEAR:
      return {
        ...state,
        compare_year: action.compare_year
      }

    default:
      return state
  }
}

export default reducer;