import * as types from './types';

function updateLatLon(lat, lon) {
  return {
    type: types.UPDATE_LATLON,
    lat: lat,
    lon: lon
  }
}

function updateRadius(radius) {
  return {
    type: types.UPDATE_RADIUS,
    radius: radius
  }
}

function updateCompareYear(year) {
  return {
    type: types.UPDATE_COMPARE_YEAR,
    compare_year: year
  }
}

export {
  updateLatLon,
  updateRadius,
  updateCompareYear
}