import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import L from 'leaflet';
import CircularProgress from '@mui/material/CircularProgress';
import ChoroplethMap from './ChoroplethMap';
import GraphForArea from './GraphForArea';

const LandPrice = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  let { isPending, mapData, graphData } = props;
  useEffect(() => {
    if (graphData && graphData.y_values.every(value => value === null) &&
      graphData.y_values_koji.every(value => value === null) &&
      graphData.y_values_kijun.every(value => value === null)) {
      setErrorMessage('地価データの範囲外です。東京23区内で住所を入力、もしくはクリックしてください');
    } else {
      setErrorMessage('');
    }
  }, [graphData]);
  var series = [
    {
      name: '全体平均',
      type: 'line',
      data: graphData === null ? [] : graphData.y_values.map(y => y > 0 ? Math.round(y / 1000) / 10 : null)
    },
    {
      name: '公示地価平均',
      type: 'column',
      data: graphData === null ? [] : graphData.y_values_koji.map(y => y > 0 ? Math.round(y / 1000) / 10 : null)
    },
    {
      name: '基準地価平均',
      type: 'column',
      data: graphData === null ? [] : graphData.y_values_kijun.map(y => y > 0 ? Math.round(y / 1000) / 10 : null)
    },
  ];
  var options = {
    chart: {
      type: 'line',
      height: '50%',
      width: '100%',
      zoom: { enabled: false },
      toolbar: { show: false }
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    xaxis: {
      categories: graphData.y_values.every(value => value === null) ? [] : graphData.x_values,
      title: {
        text: '西暦',
        // offsetY: -20,
      },
      labels: {
        show: graphData.y_values.every(value => value === null) ? false : true, // 禁用 x-axis 标签
      }
    },
    yaxis: [{
      title: {
        text: '平均地価（万円/平米）',
      }
    }]
  }

  const mapStyle = {
    opacity: 0,
    fillOpacity: 0.9
  }

  const pointToLayer = (feature, latlng) => {
    return L.circleMarker(latlng, { radius: 7.5 });
  }

  return (
    <div style={{ height: '100%' }}>
      {
        isPending ? (
          <Grid container flexDirection="column" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography>データ取得中...</Typography>
            </Grid>
          </Grid>
        ) : (
          mapData === null ? null :
            (
              <Grid container justify='center' style={{ height: '100%' }}>
                <Grid item xs={7}>
                  <ChoroplethMap
                    data={mapData}
                    isLegend={true}
                    isSelectYear={true}
                    pointToLayer={pointToLayer}
                    scale={'BuGn'}
                    steps={100}
                    mode={'e'}
                    style={mapStyle}
                    upperRatioLimit={2}
                  />
                </Grid>
                <Grid item xs={5} style={{ padding: 10 }}>
                  {errorMessage && (
                    <div style={{ color: 'red', fontWeight: 'bold', fontSize: '0.8em', padding: '10px', border: '1px solid red', borderRadius: '5px', margin: '10px 0', backgroundColor: '#ffe6e6' }}>
                      {errorMessage}
                    </div>
                  )}
                  <GraphForArea
                    options={options}
                    series={series}
                    title={'平均地価推移'}
                  />
                  <div style={{ padding: 10 }}>
                    <Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>このデータについて</Typography>
                    <Typography variant='body2' style={{ fontSize: '0.75em' }}>• 国土数値情報の地価公示データと都道府県地価調査データを加工して作成</Typography>
                    <Typography variant='body2' style={{ fontSize: '0.75em' }}>• 地図上の黒色の地点は最新の調査では測定されたが、選択した年度の調査で測定されていない地点を表す</Typography>
                    <Typography variant='body2' style={{ fontSize: '0.75em' }}>• 公示地価: 地価公示法に基づき調査・公示される各年1月1日時点の全国の標準地の価格</Typography>
                    <Typography variant='body2' style={{ fontSize: '0.75em' }}>• 基準地価: 国土利用計画法施行令に基づき、各都道府県知事によって行われた各年7月1日時点の基準値の地価</Typography>
                    <Typography variant='body2' style={{ fontSize: '0.75em' }}>• グラフの全体平均は公示地価と基準地価の両方を含む平均地価</Typography>
                  </div>
                </Grid>
              </Grid>
            )
        )
      }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isPending: state.landPrice.isPending,
    mapData: state.landPrice.mapData,
    graphData: state.landPrice.graphData
  }
}

export default connect(mapStateToProps, null)(LandPrice);