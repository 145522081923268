import reducer from './reducers';
import * as populationOperations from './operations';
import * as populationActions from './actions';
import * as populationTypes from './types';

export {
  populationOperations,
  populationActions,
  populationTypes
}

export default reducer;